/* ---------- Imports ---------- */

@import url("https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");

/* ---------- Normalize ---------- */

body,
html {
  background-color: #252525;
  color: #fff8d9;
}

a,
a:visited {
  text-decoration: none;
  color: inherit;
}
.App {
  text-align: center;
  font-family: "Open Sans", sans-serif;
}
header {
  z-index: 110;
}
nav {
  position: fixed;
  z-index: 200;
  bottom: 8px;
  left: 0;
  right: 0;
  max-height: 130px;
}
h1 {
  font-size: 1.1em;
  margin: 12px;
  -webkit-text-shadow: 8px 8px 12px 5px rgba(0, 0, 0, 0.5);
  -moz-text-shadow: 8px 8px 12px 5px rgba(0, 0, 0, 0.5);
  text-shadow: 8px 8px 12px 5px rgba(0, 0, 0, 0.5);
}
p {
  font-size: 0.6em;
}

/* ---------- Typography ---------- */
.aviera-light {
  font-family: "Averia Serif Libre", cursive;
  font-weight: 300;
  font-style: normal;
}
.aviera-light-italic {
  font-family: "Averia Serif Libre", cursive;
  font-weight: 300;
  font-style: italic;
}
.aviera-regular {
  font-family: "Averia Serif Libre", cursive;
  font-weight: 400;
  font-style: normal;
}
.aviera-regular-italic {
  font-family: "Averia Serif Libre", cursive;
  font-weight: 400;
  font-style: italic;
}
.aviera-bold {
  font-family: "Averia Serif Libre", cursive;
  font-weight: 700;
  font-style: normal;
}
.aviera-bold-italic {
  font-family: "Averia Serif Libre", cursive;
  font-weight: 700;
  font-style: italic;
}
.opensans-light {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-style: normal;
}
.opensans-light-italic {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-style: italic;
}
.opensans-regular {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.opensans-regular-italic {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: italic;
}
.opensans-semibold {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-style: normal;
}
.opensans-semibold-italic {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-style: italic;
}
.opensans-bold {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
}
.opensans-bold-italic {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-style: italic;
}
.opensans-extrabold {
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  font-style: normal;
}
.opensans-extrabold-italic {
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  font-style: italic;
}

/* ---------- Sizing & Layout ---------- */
.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.justify-center {
  justify-content: center;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-space-evenly {
  justify-content: space-evenly;
}
.justify-space-between {
  justify-content: space-between;
}
.justify-space-around {
  justify-content: space-around;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}

/* ---------- Component Classes ---------- */
.slim-header {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
}
@keyframes logo-hover {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
.slim-logo,
.slim-help {
  width: 48px;
  height: 48px;
  background-size: cover;
  margin: 8px;
  transform: scale(1);
  cursor: pointer;
}
.slim-logo:hover,
.slim-help:hover,
.slim-logo:active,
.slim-help:active,
.slim-logo:focus,
.slim-help:focus {
  animation: logo-hover 0.3s;
  transform: scale(1.2);
}
.slim-logo {
  background-image: url(assets/site-brand/op3d-home-96.png);
}
.slim-help {
  background-image: url(assets/site-brand/op3d-help-96.png);
}
.slim-subtitle {
  margin: 4px;
}
.hero-logo {
  padding: 10px 20px;
}
.hero-title {
  font-size: 5em;
  margin: 16px;
}
.content {
  padding: 60px 0 60px 0;
  height: 100%;
}
.nav-button {
  margin: 4px;
  background: none;
  border-width: 0;
  cursor: pointer;
  max-width: 60px;
  padding: 0;
  perspective: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  outline: none;
}
@keyframes button-hover {
  0% {
    transform: rotateX(-1deg) rotateY(40deg) scale(1);
  }
  100% {
    transform: rotateX(0deg) rotateY(0deg) scale(1.2);
  }
}
.nav-button > img {
  width: 100%;
  height: auto;
  border: 2px solid #fff8d9;
  transform: rotateX(-1deg) rotateY(40deg);
  -webkit-box-shadow: 8px 8px 12px 5px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 8px 8px 12px 5px rgba(0, 0, 0, 0.5);
  box-shadow: 8px 8px 12px 5px rgba(0, 0, 0, 0.5);
}
.nav-button > img:hover,
.nav-button > img:active,
.nav-button > img:focus {
  animation: button-hover 0.3s;
  transform: rotateX(0deg) rotateY(0deg) scale(1.2);
}
.picture-frame {
  /* border: 1px solid; */
  padding: 0;
  z-index: 101;
  overflow: hidden;
  transform-style: preserve-3d;
}
.picture-frame-shadow {
  -webkit-box-shadow: 15px 15px 23px 5px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 15px 15px 23px 5px rgba(0, 0, 0, 0.5);
  box-shadow: 15px 15px 23px 5px rgba(0, 0, 0, 0.5);
}
.picture-frame-border {
  border: 4px solid #fff8d9;
}
.frame-center {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border: 1px solid;
  border-radius: 50%;
}

.debug {
  z-index: 100;
  margin: 0;
  padding: 1em;
  position: relative;
  color: #ffffff;
  background-color: #000000;
  opacity: 0.5;
  border-radius: 0.5em;
  min-width: 250px;
}

.img-container {
  position: relative;
}

.pic-slice {
  margin: 0;
  position: absolute;
}

.perspective-container {
  position: relative;
  height: 100%;
  width: 100%;
  perspective: 1000px;
}

.perspective-test {
  /* position: absolute; */
  border: 1px solid;
  width: 500px;
  height: 500px;
  background-image: url(./assets/img/old-men-on-step/master_sm.png);
  background-size: cover;
  background-position: center;
  transform-style: preserve-3d;
  transform: rotateX(45deg) rotateY(45deg);
  /* perspective: 50px; */
}
.help-overlay {
  z-index: 300;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  padding: 0;
}
.help-blackout {
  position: fixed;
  background-color: #000000;
  opacity: 0.5;
  width: 100%;
  height: 100%;
}
.help-modal {
  z-index: 310;
  position: relative;
  margin-top: 100px;
  margin: 60px 16px;
  padding: 8px 8px 20px 8px;
  background-color: #000000;
  border-radius: 0.5em;
  border: 1px solid;
}

/* ---------- Responsiveness ---------- */

/* Custom iPhone Portrait */
@media (min-width: 340px) {
  h1 {
    font-size: 1.3em;
  }
}
@media (min-width: 390px) {
  h1 {
    font-size: 1.5em;
  }
  .slim-logo,
  .slim-help {
    width: 56px;
    height: 56px;
  }
  .picture-frame-border {
    border: 6px solid #fff8d9;
  }
  .help-modal {
    margin: 60px 32px;
  }
}

/* Bootstrap equivalents */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  h1 {
    font-size: 2em;
  }
  p {
    font-size: 0.8em;
  }
  .slim-logo,
  .slim-help {
    width: 60px;
    height: 60px;
  }
  .content {
    margin: 100px 0 100px 0;
  }
  .picture-frame-border {
    border: 8px solid #fff8d9;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .help-modal {
    margin: 60px 50px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .help-modal {
    margin: 60px 100px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .help-modal {
    margin: 60px 250px;
  }
}
